/* src/assets/styles/WishDetail.css */

.wish-detail-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.wish-detail-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wish-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.wish-detail-user-info {
  margin-left: 1rem;
}

.wish-detail-user-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.user-meta {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.user-meta .flag {
  width: 24px;
  height: auto;
  margin-right: 0.5rem;
}

.post-time {
  color: #888;
  font-size: 0.9rem;
}

.wish-detail-content {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.wish-reactions {
  display: flex;
  align-items: center;
}

.reaction-btn {
  background: none;
  border: none;
  color: #555;
  font-size: 1.1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reaction-btn i {
  margin-right: 0.3rem;
}

.reaction-btn.active {
  color: #007bff;
}

.reaction-btn:hover {
  color: #0056b3;
}

.like-btn.active {
  color: #68b0ab;
}

.heart-btn.active {
  color: #68b0ab;
}

.dislike-btn.active {
  color: #68b0ab;
}





/* ... código existente ... */

.wish-reactions-feed-toggle {
  margin-top: 1rem;
  cursor: pointer;
  color: #68b0ab;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.wish-reactions-feed-toggle:hover {
  text-decoration: underline;
}

.wish-reactions-feed {
  margin-top: 1rem;
  border-top: 1px solid #ddd;
  padding-top: 1rem;
}

.reaction-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.reaction-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.reaction-info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.reaction-user-name {
  font-weight: bold;
}

.reaction-type {
  display: flex;
  align-items: center;
}

.reaction-type i {
  margin-right: 0.5rem;
}

.reaction-time {
  color: #888;
  font-size: 0.9rem;
}


.nav-link {
  color: #68b0ab!important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #000000!important;
}
/* src/assets/styles/CookieConsentBar.css */

.cookie-consent-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #dddddd;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1100;
  background-color: #000000;
}

.cookie-consent-bar p {
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 10px;
  color: #ffffff;
}

.cookie-consent-bar a {
  color: #ffffff;
  text-decoration: underline;
}

.cookie-consent-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.cookie-consent-buttons button {
  background-color: #68b0ab;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.cookie-consent-buttons button:hover {
  background-color: #4e837f;
}


.accept-essential-btn { 
  background-color: yellow!important;
  color: black!important;
}

.customize-btn { 
  background-color: orange!important;
  color: black!important;
}

/* Responsividad */
@media (min-width: 600px) {
  .cookie-consent-bar {
    flex-direction: row;
    justify-content: space-between;
  }

  .cookie-consent-bar p {
    margin-bottom: 0;
    text-align: left;
  }

  .cookie-consent-buttons {
    justify-content: flex-end;
  }
}
/* src/assets/styles/HomePage.css */

/* Main Layout */
.main-container {
  display: flex;
  height: 100vh; /* Ocupa toda la ventana del navegador */
  overflow: hidden; /* Desactiva el scroll global */
}

/* Left Sidebar (Input Form) */
.left-sidebar {
  width: 250px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  top: 0; /* Fijo en la parte superior */
  height: 100vh; /* Ocupa toda la altura de la ventana */
  overflow: hidden; /* Desactiva el scroll completamente */
}

/* Ajustar los elementos internos del left-sidebar */
.left-sidebar h4,
.left-sidebar textarea,
.left-sidebar button {
  margin-bottom: 1rem; /* Espaciado consistente entre los elementos */
}

.left-sidebar textarea {
  max-height: 150px; /* Limitar la altura del textarea */
  overflow-y: auto; /* Scroll interno solo si el contenido del textarea excede */
}

.left-sidebar button {
  width: 100%;
}

.left-sidebar textarea {
  width: 100%;
  border-radius: 8px;
  padding: 0.5rem;
  border: 1px solid #dcdcdc;
  resize: none;
  background-color: #f9fafb;
  color: #333;
}

.left-sidebar button {
  background-color: #68b0ab;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.left-sidebar button:hover {
  background-color: #4e837f;
}

/* Center Collage of Wishes */
.wish-collage-container {
  flex: 1;
  margin: 0 1rem;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  overflow-y: auto; /* Importante: Habilita el scroll interno */
  height: 100vh; /* Ajusta según tu layout, pero debe ser fijo para scroll */
  display: flex;
  flex-direction: column;
}

/* Right Sidebar (Top Wishes) */
.right-sidebar {
  width: 250px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: sticky;
  top: 0; /* Fijo en la parte superior */
  height: 100vh; /* Ocupa toda la altura de la ventana */
  overflow-y: auto; /* Permite scroll interno si el contenido excede */
}

.right-sidebar h4 {
  font-weight: 600;
  margin-bottom: 1rem;
  color: #68b0ab;
}

/* Estilo para ocultar el scroll cuando no esté en uso */
.right-sidebar::-webkit-scrollbar,
.left-sidebar::-webkit-scrollbar {
  width: 5px;
}

.right-sidebar::-webkit-scrollbar-thumb,
.left-sidebar::-webkit-scrollbar-thumb {
  background-color: #68b0ab;
  border-radius: 5px;
}

.right-sidebar:hover::-webkit-scrollbar-thumb,
.left-sidebar:hover::-webkit-scrollbar-thumb {
  background-color: #4e837f;
}





/* Responsive Adjustments */
@media (max-width: 992px) {
  .main-container {
    flex-direction: column;
    height: auto;
  }

  .left-sidebar,
  .right-sidebar {
    width: 100%;
    margin-bottom: 1rem;
    position: relative;
    top: initial;
    height: fit-content;
  }

  .wish-collage-container {
    margin: 0;
    height: auto;
    flex: 1;
  }

  .wish-collage {
    column-count: 2;
  }
}

@media (max-width: 576px) {
  .left-sidebar button {
    width: 100%;
  }

  .wish-collage {
    column-count: 1;
  }
}
.contact-content {
  flex: 1;
  margin: 0 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  overflow-y: auto;
}

.contact-content h2 {
  color: #68b0ab;
  margin-bottom: 1.5rem;
}

.contact-content form {
  display: flex;
  flex-direction: column;
}

.contact-content label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #68b0ab;
}

.contact-content input,
.contact-content textarea {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  resize: none;
  background-color: #f9fafb;
  color: #333;
}

.contact-content button {
  background-color: #68b0ab;
  border: none;
  padding: 0.75rem;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-content button:hover {
  background-color: #4e837f;
}
/* src/assets/styles/PaymentOptionsModal.css */

.payment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.payment-modal-content {
  background-color: #fff;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  border-radius: 8px;
  position: relative;
}

.payment-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-modal-header h4 {
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.payment-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.payment-option-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.payment-option-btn:hover {
  background-color: #e0e0e0;
}

.payment-option-btn span {
  font-size: 1rem;
}

.cancel-btn {
  width: 100%;
  padding: 10px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-btn:hover {
  background-color: #e60000;
}
.wish-collage-container {
  flex: 1;
  margin: 0 1rem;
  background-color: #fff;
  border-radius: 10px;
/*  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);*/
  padding: 1rem;
  padding-bottom: 2rem;
  overflow-y: auto;
  flex-grow: 1;
}

.wish-collage {
  column-count: 3;
  column-gap: 1rem;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


.end-of-list-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem; /* Espacio antes del sentinel o del final */
  opacity: 0.8;
}

.end-line {
  width: 50%;
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 1rem;
}

.end-message {
  font-size: 1.1rem;
  color: #555;
  text-align: center;
  background: #f8f9fa;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}



.dev-auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.dev-auth-container h2 {
  margin-bottom: 20px;
}

.dev-auth-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dev-auth-container label {
  margin-bottom: 10px;
}

.dev-auth-container input {
  padding: 10px;
  margin-bottom: 20px;
  width: 300px;
  max-width: 80%;
}

.dev-auth-container button {
  padding: 10px 20px;
}
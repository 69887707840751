.tmw-loading-animation {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
}

.tmw-bar {
  background-color: #4caf50;
  animation: wave 1.2s infinite ease-in-out;
}

.tmw-bar:nth-child(2) {
  animation-delay: -1.1s;
}

.tmw-bar:nth-child(3) {
  animation-delay: -1s;
}

.tmw-bar:nth-child(4) {
  animation-delay: -0.9s;
}

.tmw-bar:nth-child(5) {
  animation-delay: -0.8s;
}

@keyframes wave {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}
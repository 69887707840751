.wish-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  padding: 0px 1.5rem;
  margin-bottom: 1rem;
  display: inline-block;
  width: 100%;
  break-inside: avoid;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.wish-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.wish-card .wish-number {
  font-weight: normal;
  color: #68b0ab;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.wish-card p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1rem;
}



.wish-card .user-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.wish-card .user-info .reaction-avatar {
  margin-right: 10px;
}

.wish-card .user-details {
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
}

.wish-card .user-details .wish-number {
  font-weight: normal;
  color: #68b0ab;
  margin-bottom: 0.2rem;
  cursor: pointer;
}




/* Bandera del País */
.wish-card .flag {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 20px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.wish-card .reactions {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.wish-card .reaction-buttons {
  display: flex;
  align-items: center;
  flex: 1;
}

.wish-card .reaction-buttons .reaction-btn {
  margin-right: 0.5rem;
}

.wish-card .reaction-buttons .reaction-btn:last-child {
  margin-right: 0;
}

.wish-card .time-stamp {
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-size: 0.875rem;
  color: #6c757d;
}

.reaction-btn i {
  margin-right: 5px;
}

.reaction-btn:hover {
  color: #68b0ab;
} 

.reaction-btn.active {
  color: #28a745;
}


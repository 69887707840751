.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.message-modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  position: relative;
}

.message-modal.error {
  border-left: 5px solid #d9534f;
}

.message-modal.warning {
  border-left: 5px solid #f0ad4e;
}

.message-modal.info {
  border-left: 5px solid #5bc0de;
}

.modal-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.modal-message {
  font-size: 1rem;
  margin-bottom: 20px;
}

.close-btn {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.close-btn:hover {
  background: #0056b3;
}
/* src/assets/styles/ErrorBoundary.css */
.error-boundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  color: #333;
}

.error-boundary-content {
  max-width: 600px;
}

.error-icon {
  font-size: 3rem;
  color: #dc3545;
  margin-bottom: 1rem;
}

.error-boundary h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.error-boundary p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.error-actions button {
  margin: 0 0.5rem;
}

.error-details {
  text-align: left;
  margin-top: 1rem;
  padding: 1rem;
  background: #f1f1f1;
  border-radius: 5px;
  overflow: auto;
  font-family: monospace;
}

.error-details summary {
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
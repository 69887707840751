/* src/assets/styles/WishForm.css */

/* Estilos Generales */
.user-info-box {
  width: 100%;
  background-color: #e3fdfd;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@keyframes highlightBlink {
  0% { background-color: #e3fdfd; }
  25% { background-color: #fff9c4; }
  50% { background-color: #e3fdfd; }
  75% { background-color: #fff9c4; }
  100% { background-color: #e3fdfd; }
}



.reactions-readonly {
  border-top: 1px solid #dcdcdc;
  display: flex;
  justify-content: flex-start;
}

.reaction-buttons-readonly {
  display: flex;
  gap: 10px;
}

.reaction-btn-readonly {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #333;
}

.reaction-btn-readonly i {
  margin-right: 5px;
}

.like-btn-readonly i {
  color: #007bff;
}

.heart-btn-readonly i {
  color: #ff4757;
}

.dislike-btn-readonly i {
  color: #555;
}



.user-info-box.highlight {
  animation: highlightBlink 3s ease-in-out;
}

.user-info-header {
  display: flex;
  align-items: center;
}

.user-avatar img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.user-details {
  margin-left: 1rem;
}

.user-details h5 {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
}

.user-details p {
  margin: 0;
  font-size: 0.75rem;
  color: #555;
}

.user-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
}

.user-actions button {
  background: none;
  border: none;
  color: #68b0ab;
  font-size: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.user-actions button:hover {
  color: #4e837f;
}

.auth-actions {
  margin-bottom: 1.5rem;
}

#publishBtn {
  background-color: #68b0ab;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 5px!important;
}

#publishBtn:hover {
  background-color: #4e837f;
}

.auth-btn.login {
  background-color: rgba(104, 176, 171, 0.8); /* 20% más claro */
  border: none;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: center;
}

.auth-btn.login:hover {
  background-color: rgba(78, 131, 127, 0.5); /* Hover más oscuro */
}

.auth-btn.register {
  background-color: rgba(104, 176, 171, 0.7); /* 30% más claro */
  border: none;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.auth-btn.register:hover {
  background-color: rgba(78, 131, 127, 0.3); /* Hover más oscuro */
}

.error-message {
  color: #ff4d4f;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

/* Estilos para el Título y Contenido del Deseo */
aside h5 {
  margin: 1rem 0 0.5rem 0;
  font-size: 1rem;
  color: #333;
}

aside p {
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 1rem;
}

textarea#wishInput {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  resize: vertical;
  font-size: 0.9rem;
}

/* Botón Flotante de Perfil */
.profile-float-btn {
  position: fixed;
  top: 1rem;
  left: 1rem;
  background-color: #68b0ab;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  display: none; /* Oculto por defecto */
  align-items: center;
  justify-content: center;
  padding: 0;
}

.profile-float-btn img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

/* Media Queries para Vista Móvil */
@media (max-width: 768px) {
  /* Ocultar la sección completa del perfil en móvil */
  .user-info-box {
    display: none;
  }

  /* Mostrar el botón flotante de perfil */
  .profile-float-btn {
    display: flex;
  }

  /* Ajustes generales en móvil */
  .auth-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .auth-btn.login,
  .auth-btn.register {
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  /* Título y Contenido del Deseo */
  aside h5 {
    font-size: 0.95rem;
    margin: 1rem 0 0.5rem 0;
  }

  aside p {
    font-size: 0.85rem;
  }

  textarea#wishInput {
    padding: 0.5rem;
    font-size: 0.85rem;
  }

  #publishBtn {
    padding: 0.6rem 1.2rem;
    font-size: 0.95rem;
    margin-top: 5px!important;
  }

  /* Error Message */
  .error-message {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  /* Ajustes adicionales para móviles pequeños */
  .profile-float-btn {
    width: 60px;
    height: 60px;
    top: 0.75rem;
    left: 0.75rem;
  }

  /* Ajustes para el modal de perfil */
  .profile-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1002;
  }

  .profile-modal {
    background-color: #fff;
    border-radius: 8px;
    width: 95%;
    max-width: 400px;
    padding: 0.75rem;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .close-modal-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #555;
  }

  .close-modal-btn:hover {
    color: #000;
  }

  .user-avatar img {
    width: 40px;
    height: 40px;
  }

  .user-details h5 {
    font-size: 0.8rem;
  }

  .user-details p {
    font-size: 0.65rem;
  }

  .user-actions {
    margin-top: 0.5rem;
    justify-content: center;
  }

  .user-actions button {
    font-size: 0.8rem;
    margin-right: 0.2rem;
  }
}



/* Para vista móvil */
@media (max-width: 768px) {
  .auth-actions {
    display: flex;
    flex-direction: row; /* Cambia de columna a fila */
    justify-content: space-between; /* Agrega espacio entre los botones */
    gap: 0.5rem; /* Espaciado entre los botones */
  }

  .auth-btn.login,
  .auth-btn.register {
    flex: 1; /* Ambos botones ocupan el mismo ancho disponible */
    font-size: 0.9rem;
    padding: 0.5rem;
    display: flex; /* Asegura alineación interna */
    align-items: center; /* Centra verticalmente el texto */
    justify-content: center; /* Centra horizontalmente el texto */
    text-align: center; /* Asegura que el texto esté centrado */
    height: 3rem; /* Fija un alto uniforme para ambos botones */
    box-sizing: border-box; /* Incluye padding y border en el cálculo del alto */
  }
}






/* Sección de compartir */
.share-single-button-section {
  margin-top: 1.5rem;
  text-align: center;
}

.share-single-btn {
  position: relative;
  background: linear-gradient(135deg, #8fc6c2, #6da59c);
  color: #fff;
  border: none;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  letter-spacing: 1.5px;
  margin-bottom: 0.5rem;
  line-height: 14px;

  /* Fondo con patrón de símbolos '$' */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='200' height='200'><text x='10' y='20' fill='%23ffffff' fill-opacity='0.08' font-size='20' font-family='sans-serif'>$</text><text x='30' y='50' fill='%23ffffff' fill-opacity='0.05' font-size='15' font-family='sans-serif'>$</text><text x='60' y='40' fill='%23ffffff' fill-opacity='0.1' font-size='25' font-family='sans-serif'>$</text><text x='90' y='30' fill='%23ffffff' fill-opacity='0.03' font-size='10' font-family='sans-serif'>$</text><text x='120' y='70' fill='%23ffffff' fill-opacity='0.08' font-size='18' font-family='sans-serif'>$</text><text x='150' y='20' fill='%23ffffff' fill-opacity='0.05' font-size='12' font-family='sans-serif'>$</text><text x='170' y='50' fill='%23ffffff' fill-opacity='0.07' font-size='22' font-family='sans-serif'>$</text><text x='20' y='110' fill='%23ffffff' fill-opacity='0.06' font-size='30' font-family='sans-serif'>$</text><text x='50' y='130' fill='%23ffffff' fill-opacity='0.04' font-size='14' font-family='sans-serif'>$</text><text x='80' y='150' fill='%23ffffff' fill-opacity='0.09' font-size='28' font-family='sans-serif'>$</text><text x='100' y='90' fill='%23ffffff' fill-opacity='0.02' font-size='60' font-family='sans-serif'>$</text><text x='130' y='170' fill='%23ffffff' fill-opacity='0.1' font-size='35' font-family='sans-serif'>$</text><text x='160' y='130' fill='%23ffffff' fill-opacity='0.08' font-size='24' font-family='sans-serif'>$</text><text x='190' y='90' fill='%23ffffff' fill-opacity='0.05' font-size='20' font-family='sans-serif'>$</text><text x='40' y='180' fill='%23ffffff' fill-opacity='0.07' font-size='18' font-family='sans-serif'>$</text><text x='70' y='100' fill='%23ffffff' fill-opacity='0.03' font-size='12' font-family='sans-serif'>$</text></svg>");
  background-repeat: repeat;
  background-size: 200px 200px;
}

.share-single-btn:hover {
  background: linear-gradient(135deg, #6da59c, #8fc6c2);
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='200' height='200'><text x='10' y='20' fill='%23ffffff' fill-opacity='0.08' font-size='20' font-family='sans-serif'>$</text><text x='30' y='50' fill='%23ffffff' fill-opacity='0.05' font-size='15' font-family='sans-serif'>$</text><text x='60' y='40' fill='%23ffffff' fill-opacity='0.1' font-size='25' font-family='sans-serif'>$</text><text x='90' y='30' fill='%23ffffff' fill-opacity='0.03' font-size='10' font-family='sans-serif'>$</text><text x='120' y='70' fill='%23ffffff' fill-opacity='0.08' font-size='18' font-family='sans-serif'>$</text><text x='150' y='20' fill='%23ffffff' fill-opacity='0.05' font-size='12' font-family='sans-serif'>$</text><text x='170' y='50' fill='%23ffffff' fill-opacity='0.07' font-size='22' font-family='sans-serif'>$</text><text x='20' y='110' fill='%23ffffff' fill-opacity='0.06' font-size='30' font-family='sans-serif'>$</text><text x='50' y='130' fill='%23ffffff' fill-opacity='0.04' font-size='14' font-family='sans-serif'>$</text><text x='80' y='150' fill='%23ffffff' fill-opacity='0.09' font-size='28' font-family='sans-serif'>$</text><text x='100' y='90' fill='%23ffffff' fill-opacity='0.02' font-size='60' font-family='sans-serif'>$</text><text x='130' y='170' fill='%23ffffff' fill-opacity='0.1' font-size='35' font-family='sans-serif'>$</text><text x='160' y='130' fill='%23ffffff' fill-opacity='0.08' font-size='24' font-family='sans-serif'>$</text><text x='190' y='90' fill='%23ffffff' fill-opacity='0.05' font-size='20' font-family='sans-serif'>$</text><text x='40' y='180' fill='%23ffffff' fill-opacity='0.07' font-size='18' font-family='sans-serif'>$</text><text x='70' y='100' fill='%23ffffff' fill-opacity='0.03' font-size='12' font-family='sans-serif'>$</text></svg>");
  background-repeat: repeat;
  background-size: 200px 200px;
}

.share-single-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.copied-message {
  color: #333;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}


/* src/assets/styles/WishConfirmationModal.css */

.button-group {
  text-align: center!important;
}

.login-btn {
  background-color: #68b0ab;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0px!important;
}
.register-btn:hover {
  background-color: #4e837f;
}


.register-btn {
  background-color: rgba(104, 176, 171, 0.7)!important; /* 30% más claro */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0px!important;
}

.register-btn:hover {
  background-color: rgba(78, 131, 127, 0.5)!important; /* Hover más oscuro */
}

.close-btn {
  background-color: #d1ecf1!important;
  color: darkgray!important;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #bee5eb!important;
}

.textCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
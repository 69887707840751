/* Estilos generales del modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Asegura que esté por encima de otros elementos */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
}

.modal-content h4 {
  margin-top: 0;
  color: #68b0ab;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  margin-top: 10px;
  color: #68b0ab;
  font-weight: bold;
}

.modal-content input {
  margin-top: 5px;
  padding: 0.5rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #f9fafb;
}

.modal-content button[type="submit"] {
/*  margin-top: 20px;*/
  background-color: #68b0ab;
  color: white;
  border: none;
/*  padding: 10px;*/
  border-radius: 8px;
  cursor: pointer;
}

.register-btn {
  margin-top: 20px!important;
  padding: 10px!important;
}
.login-btn {
  margin-top: 20px!important;
  padding: 10px!important;
}

.cancel-custom-btn {
  background-color: gray;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.primary-custom-btn {
  background-color: #68b0ab;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.modal-content button[type="submit"]:hover {
  background-color: #4e837f;
}

.close-btn {
  margin-top: 10px;
  background: none;
  border: none;
  color: #68b0ab;
  cursor: pointer;
}

.close-btn:hover {
  color: white;
}

.error-message {
  margin-top: 10px;
  color: red;
}




/* src/assets/styles/AuthModal.css */

.captcha-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra horizontalmente */
  justify-content: center; /* Centra verticalmente si es necesario */
  margin-top: 15px; /* Espacio superior opcional */
}

.captcha-container label {
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

.captcha-image-refresh {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.captcha-image {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  width: 150px; /* Ajusta el tamaño según tus necesidades */
  height: 50px; /* Ajusta el tamaño según tus necesidades */
}

.refresh-captcha-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #007bff; /* Color azul similar a Bootstrap */
  
}

.captcha-input {
  text-align: center!important;
}

.refresh-captcha-btn:hover {
  color: #0056b3; /* Color más oscuro al pasar el cursor */
}

.captcha-container input {
  width: 100%;
  max-width: 200px; /* Ancho máximo opcional */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}




/* Estilos para el tooltip */
.auth-tooltip-icon {
  display: inline-block;
  font-weight: bold;
  border: 1px solid #007bff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
  margin-left: 8px;
  transition: background-color 0.3s, color 0.3s;

}

.auth-tooltip-icon:hover {
  background-color: #007bff;
  color: #fff;
}

/* Opcional: Ajustar el label para alinear el icono correctamente */
.referral-label {
  display: flex;
  align-items: center;
}

.auth-tooltip {
    z-index: 20000 !important;
}


/* Estilos generales del modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Asegura que esté por encima de otros elementos */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
}

.modal-content h4 {
  margin-top: 0;
  color: #68b0ab;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  margin-top: 10px;
  color: #68b0ab;
  font-weight: bold;
}

.modal-content input {
  margin-top: 5px;
  padding: 0.5rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #f9fafb;
}

.modal-content button[type="submit"] {
/*  margin-top: 20px;*/
  background-color: #68b0ab;
  color: white;
  border: none;
/*  padding: 10px;*/
  border-radius: 8px;
  cursor: pointer;
}

.modal-content button[type="submit"]:hover {
  background-color: #4e837f;
}

.close-btn {
  margin-top: 10px;
  background: none;
  border: none;
  color: #68b0ab;
  cursor: pointer;
}

.close-btn:hover {
  color: white;
}



.user-info-box-modal {
  width: 100%;
  background-color: #e3fdfd;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-avatar img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}


/* src/assets/styles/MainLayout.css */

/* Estilos para la franja de desarrollo */
.development-banner {
  width: 100%;
  background-color: orange;
  color: red;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  position: relative; /* Fija la franja en la parte superior */
  top: 0;
  left: 0;
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
  overflow: hidden; /* Oculta cualquier contenido desbordado */
  height: 40px;
}

/* Estilos para el contenedor de la marquesina */
.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 360s linear infinite;
}

/* Animación para el desplazamiento de la marquesina */
@keyframes marquee {
  0% {
    transform: translateX(0); /* Inicia dentro de la pantalla */
  }
  100% {
    transform: translateX(-100%); /* Mueve el texto completamente fuera de la pantalla */
  }
}

/* Estilos para el contenido principal cuando la franja está presente */
.main-content.with-banner {
  padding-top: 50px; /* Ajusta según la altura de la franja */
}

/* Estilos para el contenido principal sin la franja */
.main-content {
  padding-top: 0;
}
.top-wish {
  padding: 1rem;
  background-color: #e3fdfd;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: background-color 0.3s ease;
  font-size: 0.85rem; /* Fuente más pequeña */
}

.top-wish:hover {
  background-color: #d4f1f4;
}

/* Número de rango en la parte superior derecha */
.top-wish .rank-number {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #68b0ab;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.9rem;
}

/* Información del usuario */
.top-wish .user-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.top-wish .user-info img.avatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 8px;
}

.top-wish .user-info img.flag {
  width: 20px;
  height: 15px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-left: 5px;
}

.top-wish .wish-content {
  margin-bottom: 0.5rem;
}

.top-wish .wish-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  color: #555;
}

.top-wish .likes-btn {
  background: none;
  border: none;
  color: #8e9aaf;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease;
}

.top-wish .likes-btn i {
  margin-right: 5px;
}

.top-wish .likes-btn:hover {
  color: #68b0ab;
}

.top-wish .time {
  font-style: italic;
}
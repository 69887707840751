/* src/assets/styles/LanguageSelectorModal.css */
.modal-language-selector {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

.modal-content-language-selector {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
}

.modal-content-language-selector h4 {
  margin-top: 0;
  color: #68b0ab;
}

.modal-content-language-selector ul {
  list-style: none;
  padding: 0;
}

.modal-content-language-selector li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.modal-content-language-selector li:hover {
  background-color: #f1f1f1;
}

.modal-content-language-selector button {
  margin-top: 20px;
  background-color: #68b0ab;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
}

.modal-content-language-selector button:hover {
  background-color: #4e837f;
}
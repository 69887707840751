/* Center Configuration Form */
.config-container {
  flex: 1;
  margin: 0 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  overflow-y: auto;
  flex-grow: 1;
}

.config-form {
  width: 100%;
  padding: 1rem;
}

.config-form h4 {
  font-weight: 600;
  color: #68b0ab;
  margin-bottom: 1.5rem;
}

form button[type="submit"] {
/*  margin-top: 20px;*/
  background-color: #68b0ab;
  color: white;
  border: none;
/*  padding: 10px;*/
  border-radius: 8px;
  cursor: pointer;
}


.custom-tabs .nav-link {
  color: #68b0ab;
}

.custom-tabs .nav-link:hover {
  color: #4e837f;
}

.custom-tabs .nav-link.active {
  color: #68b0ab;
}
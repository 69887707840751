/* Footer minimalista */
.footer {
  background-color: #fff;
  border-top: 1px solid #ddd;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  width: 100%;
  max-width: 1200px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Contenedor principal de enlaces y elementos derechos */
.footer-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Enlaces del footer */
.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-links a {
  color: #68b0ab;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Área derecha del footer (botón de idioma y copyright) */
.footer-right {
  display: flex;
  align-items: center;
}

.language-change-btn {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  color: #68b0ab;
  font-size: 0.75rem;
  cursor: pointer;
}

.language-change-btn:hover {
  text-decoration: underline;
}

.language-icon {
  font-size: 0.9rem;
}

.footer-separator {
  margin: 0 10px;
  color: #68b0ab;
}

.footer-separator-mobile {
  margin: 0 1px;
  color: #68b0ab;
}


/* Información adicional */
.footer-info {
  color: #666;
  font-size: 0.7rem;
}

/* Botón de cambio de idioma flotante */
.language-float-btn {
  position: fixed;
  bottom: -1px;
  right: 1px;
  width: 40px;
  height: 40px;
  background-color: #68b0ab;
  color: #fff;
  border: none;
  border-radius: 50%;
  display: none; /* Oculto por defecto */
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1100;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.language-float-btn:hover {
  background-color: #4e837f;
  transform: translateY(-3px);
}

/* Mostrar/ocultar elementos según el dispositivo */
.footer-mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .footer-content {
    padding: 0.5rem;
  }

  /* Reorganizar elementos para móvil */
  .footer-main {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .footer-links {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-links a {
    font-size: 0.6rem;
  }

  .footer-right {
    display: none; /* Ocultamos el área derecha en móvil */
  }

  /* Mostrar elementos solo en móvil */
  .footer-mobile-only {
    display: block;
    font-size: 0.75rem;
    text-align: center;
    margin-top: 5px;
  }

  /* Mostrar el botón flotante de cambio de idioma */
  .language-float-btn {
    display: flex;
  }

  .footer-separator-dash {
    display: none; 
  }

}

@media (min-width: 769px) {
  /* Ocultar el botón flotante en escritorio */
  .language-float-btn {
    display: none;
  }

  
}
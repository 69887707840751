/* Header */
@font-face {
  font-family: 'Shrikhand-Regular';
  src: url('../fonts/Shrikhand-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.tmw-title {
  font-family: 'Shrikhand-Regular', serif!important;
}

.header {
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left h1 {
  font-size: 1.5rem;
  color: #68b0ab;
  margin: 0;
  white-space: nowrap;
}

.header-left .search-input {
  position: relative;
  margin-left: 1rem;
}

.header-left .search-input input {
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  outline: none;
  width: 250px;
  transition: width 0.3s ease;
  box-sizing: border-box;
}

.header-left .search-input input:focus {
  outline: none;
}

/*.header-left .search-input i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #68b0ab;
}*/

.search-input .bi-search,
.search-input .bi-x-circle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.search-input .bi-x-circle {
  right: 40px;
}


.header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.h1 {
  text-decoration: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }

  .header-left {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 0.75rem;
  }

  .header-left h1 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  .header-left .search-input {
    margin: 0.5rem 0;
/*    width: 100%;*/
  }

  .header-left .search-input input {
    width: 100%;
/*    max-width: 300px;*/
  }

  .header-right {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
  }

  .header-right div {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .header-left h1 {
    font-size: 1.2rem;
  }

  .header-left .search-input input {
    max-width: 250px;
  }

  .header-right div {
    font-size: 0.9rem;
  }
}
/* src/assets/styles/WishConfirmationModal.css */
.cancel-btn {
  background-color: #f8d7da!important;
  color: darkgray!important;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #f5c6cb!important;
}

.save-btn {
  background-color: #d1ecf1!important;
  color: darkgray!important;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #bee5eb!important;
}
/* src/assets/styles/TopWishesMobileButton.css */

/* Estilos para el botón flotante */
.top-10-button {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #68b0ab;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Efecto hover */
.top-10-button:hover {
  background-color: #4e837f;
}

/* Ocultar el botón en escritorio */
@media (min-width: 768px) {
  .top-10-button {
    display: none;
  }
}
/* src/assets/styles/WishEditor.css */
.wish-editor-container {
  max-width: 250px;
  margin: 0 auto;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.wish-editor-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  padding: 5px;
}

.wish-editor-preview {
white-space: pre-wrap;
  word-wrap: break-word;
  pointer-events: none;
  color: #333;
  position: absolute;
  top: 12px;
  left: 12px;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  overflow-y: hidden; /* Ocultar desbordamiento */
  font-size: 12px;
  font-family: inherit; /* Usa la misma fuente que el textarea */
  
  
}

/*.wish-editor-textarea {
  width: 100%;
  height: 150px;
  border: none!important;
  outline: none!important;
  resize: none!important;
  fontSize: 12px;
  fontFamily: inherit;
  background: transparent!important;
  zIndex: 2;
  position: relative;
  color: transparent;
  caretColor: #000!important;
}*/

.wish-editor-error {
  color: red;
  margin-top: 10px;
  font-size: 12px;
}

.wish-editor-button {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
}

.wish-editor-button:hover {
  background-color: #0056b3;
}

.wish-editor-highlight {
  font-weight: bold;
  cursor: pointer;
}

.wish-editor-highlight.hashtag {
  color: #1da1f2;
  font-size: inherit; /* Asegúrate de que el tamaño sea consistente */
  line-height: inherit; /* Mantén el interlineado consistente */
}

.wish-editor-highlight.mention {
  color: #ff4500; 
}

.wish-editor-counter {
  font-size: 10px;
  color: #555;
  text-align: right;
  margin-top: 5px;
}

@media (max-width: 992px) {
  .wish-editor-container {
    max-width: 450px !important;
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .wish-editor-container {
    max-width: 100%!important;
    width: 100%!important;
  }
}

/* src/assets/styles/StaticPageContent.css */

.hide-on-mobile {
  display: none;
}

@media (min-width: 768px) {
  .hide-on-mobile {
    display: block;
  }
}

.static-page-content {
  flex: 1;
  margin: 0 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  overflow-y: auto;
}

.static-page-content h2 {
  color: #68b0ab;
  margin-bottom: 1.5rem;
}

.static-page-content p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.static-page-content .rules-link {
  color: #007bff;
  text-decoration: none;
}

.static-page-content .rules-link:hover {
  text-decoration: underline;
}



/* Botón de Continuar */
.continue-btn {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #68b0ab;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.continue-btn:hover {
  background-color: #5a9a94;
}


.share-section {
  margin: 1.5rem 0;
  text-align: center;
}

.share-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Dos columnas */
  gap: 1rem; /* Espacio entre botones */
}

.share-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.share-btn i {
  margin-right: 0.5rem;
}

/* Twitter */
.share-btn.twitter {
  background-color: #1DA1F2;
}

.share-btn.twitter:hover {
  background-color: #0d95e8;
}

/* Facebook */
.share-btn.facebook {
  background-color: #3b5998;
}

.share-btn.facebook:hover {
  background-color: #2d4373;
}

/* WhatsApp */
.share-btn.whatsapp {
  background-color: #25D366;
}

.share-btn.whatsapp:hover {
  background-color: #1ebe5d;
}

/* Telegram */
.share-btn.telegram {
  background-color: #0088cc;
}

.share-btn.telegram:hover {
  background-color: #007ab8;
}

/* LinkedIn */
.share-btn.linkedin {
  background-color: #0077b5;
}

.share-btn.linkedin:hover {
  background-color: #006699;
}

/* Pinterest */
.share-btn.pinterest {
  background-color: #e60023;
}

.share-btn.pinterest:hover {
  background-color: #cc001f;
}

/* Email */
.share-btn.email {
  background-color: #6e6e6e;
}

.share-btn.email:hover {
  background-color: #5c5c5c;
}
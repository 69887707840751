/* src/assets/styles/HashtagText.css */



.HashtagText-highlight-hashtag {
  color: #1da1f2; /* Azul tipo Twitter */
  font-weight: bold;
  cursor: pointer;
}

/*.HashtagText-highlight-hashtag:hover {
  text-decoration: underline;
}*/